.container {
  max-width: 1200px;
  margin-left: 50px;
  margin-right: 50px;
}

.table-custom th, .table-custom td {
  padding: 20px 10px;
  vertical-align: middle;
}

.table-custom th:nth-child(1), .table-custom td:nth-child(1) {
  width: 20%; /* Reduced width for Shopify Order ID */
  text-align: center;
}

.table-custom th:nth-child(2), .table-custom td:nth-child(2) {
  width: 20%; /* Reduced width for Date Created */
  text-align: center;
}

.table-custom th:nth-child(3), .table-custom td:nth-child(3) {
  width: 20%; /* Reduced width for Order Total */
  text-align: center; /* Center-align text */
}

.table-custom th:nth-child(4), .table-custom td:nth-child(4) {
  width: 40%; /* Increased width for Action */
  text-align: center;
}

.table-custom td {
  height: calc(1.2 * 50px); /* Increased row height by 20% */
}

.table-custom .btn-primary {
  height: 2rem; /* Reduce the height */
  padding: 0.2rem 1rem; /* Adjust padding to reduce height while keeping width */
}

.price-wrapper {
  display: inline-block;
  text-align: right;
  padding-right: 15px;
  min-width: 80px; /* Adjust width as needed */
}

.modal-body .m-2 {
  margin: 20px 10px;
}

.card-header {
  font-weight: bold;
  font-size: 1.5rem;
}

/* Location buttons - make them a standard size with responsive text */
.location-btn {
  width: 95px; /* Set a fixed width for the buttons */
  height: 50px; /* Set a fixed height for the buttons */
  font-size: 1rem; /* Base font size */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Standard margin */
  padding: 10px; /* Padding to ensure proper spacing */
  border-radius: 4px; /* Rounded corners */
}

@media (max-width: 576px) {
  .location-btn {
    width: 100px; /* Smaller width on small screens */
    font-size: 0.9rem; /* Adjust font size on small screens */
  }
}

@media (min-width: 768px) {
  .location-btn {
    width: 200px; /* Larger width on medium to large screens */
    font-size: 1.1rem; /* Adjust font size on medium to large screens */
  }
}

.btn {
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
}
